import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
export default defineComponent({
    setup() {
        const router = useRouter();
        const goBack = () => {
            router.back();
        };
        const viewOrder = () => {
            router.push('/orderList');
        };
        const goHome = () => {
            router.push('/PointsMall');
        };
        return {
            goBack,
            viewOrder,
            goHome
        };
    }
});
