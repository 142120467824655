import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementPlus, { ElMessage } from 'element-plus';
import 'element-plus/dist/index.css';
import './assets/global.css'; // 引入全局样式
import 'amfe-flexible';
import './utils/index';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import zhCn from 'element-plus/es/locale/lang/zh-cn';
// 如果使用 Day.js 进行日期处理，导入中文语言包
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import apiClient from './api/http';
// 将 Day.js 语言设置为中文
dayjs.locale('zh-cn');
const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component);
}
app.use(ElementPlus, { locale: zhCn });
app.use(router);
app.use(store);
app.config.globalProperties.$axios = apiClient;
// 全局配置 ElMessage
app.config.globalProperties.$message = ElMessage;
app.mount('#app');
