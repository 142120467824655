import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { addAddress, EditAddress } from '@/api/mall';
import regionData from '@/assets/region.json';
export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const addressFormRef = ref(null);
        const isEditMode = ref(false);
        const addressForm = ref({
            id: null,
            userName: '',
            userPhone: '',
            region: [],
            detailedAddress: '',
            defaultFlag: false,
        });
        const rules = {
            userName: [
                { required: true, message: '请填写收货人姓名', trigger: 'blur' },
            ],
            userPhone: [
                { required: true, message: '请填写收货手机号', trigger: 'blur' },
                { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' },
            ],
            region: [
                { type: 'array', required: true, message: '请选择所在省市区', trigger: 'change' },
            ],
            detailedAddress: [
                { required: true, message: '请填写详细地址，例如101室', trigger: 'blur' },
            ],
        };
        const regionOptions = ref(regionData);
        const cascaderProps = {
            expandTrigger: 'hover',
            multiple: false,
        };
        const goBack = () => {
            router.back();
        };
        const saveAddress = async () => {
            if (addressFormRef.value) {
                addressFormRef.value.validate(async (valid) => {
                    if (valid) {
                        const fullRegion = addressForm.value.region.join(' ');
                        const fullAddress = `${fullRegion} ${addressForm.value.detailedAddress}`;
                        const newAddress = {
                            ...addressForm.value,
                            userAddress: fullAddress,
                            defaultFlag: addressForm.value.defaultFlag ? 1 : 0,
                        };
                        try {
                            const res = isEditMode.value
                                ? await EditAddress(newAddress)
                                : await addAddress(newAddress);
                            if (res.data.code === 200) {
                                ElMessage.success(isEditMode.value ? '地址修改成功' : '地址添加成功');
                                router.back();
                            }
                            else {
                                ElMessage.error(res.data.message);
                            }
                        }
                        catch (error) {
                            ElMessage.error('操作失败，请重试');
                        }
                    }
                    else {
                        console.log('请填写完整的地址信息');
                    }
                });
            }
        };
        onMounted(() => {
            const address = route.query.address;
            if (address) {
                console.log(address);
                isEditMode.value = true;
                try {
                    const parsedAddress = JSON.parse(address); // 确保地址数据以字符串形式传递，并解析
                    addressForm.value = {
                        ...parsedAddress,
                        region: parsedAddress.userAddress.split(' ').slice(0, -1),
                        detailedAddress: parsedAddress.userAddress.split(' ').pop(),
                        defaultFlag: parsedAddress.defaultFlag === 1 ? true : false,
                    };
                }
                catch (error) {
                    console.error('解析地址参数失败:', error);
                }
            }
        });
        return {
            addressForm,
            addressFormRef,
            rules,
            regionOptions,
            cascaderProps,
            goBack,
            saveAddress,
            isEditMode,
        };
    },
});
