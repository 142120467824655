import { defineComponent, reactive, toRefs, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const state = reactive({
            activePage: route.name || 'PointsMall'
        });
        const navigateTo = async (page) => {
            if (page === 'PointsMall') {
                await router.push('/pointsMall');
                state.activePage = 'PointsMall';
            }
            else if (page === 'Profile') {
                await router.push('/proFile');
                state.activePage = 'Profile';
            }
        };
        watch(route, (newRoute) => {
            state.activePage = newRoute.name;
        });
        return {
            ...toRefs(state),
            navigateTo
        };
    }
});
