import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';
import { ElMessage } from 'element-plus';
import { registers, logins, captcha } from '@/api/login';
import CryptoJS from 'crypto-js';
export default defineComponent({
    setup() {
        const router = useRouter();
        // 初始化验证码和随机数
        const generatedCaptcha = ref('');
        const randomNumber = ref(null);
        const loginFormRef = ref(null);
        const registerFormRef = ref(null);
        const showLoginDialog = ref(false);
        const showRegisterDialog = ref(false);
        const disabled = ref(true);
        const loginForm = ref({
            loginName: '',
            password: '',
            captcha: ''
        });
        const registerForm = ref({
            loginName: '',
            userName: '',
            password: '',
            confirmPassword: ''
        });
        const loginRules = {
            loginName: [
                { required: true, message: '请输入账号', trigger: 'change' },
                { pattern: /^[a-zA-Z0-9]+$/, message: '账号只能包含字母和数字', trigger: 'change' }
            ],
            password: [{ required: true, message: '请输入密码', trigger: 'change' }],
            captcha: [{ required: true, message: '请输入验证码', trigger: 'change' }]
        };
        const registerRules = {
            loginName: [
                { required: true, message: '请输入账号', trigger: 'change' },
                { pattern: /^[a-zA-Z0-9]+$/, message: '账号只能包含字母和数字', trigger: 'change' }
            ],
            userName: [{ required: true, message: '请输入姓名', trigger: 'change' }],
            password: [{ required: true, message: '请输入密码', trigger: 'change' }],
            confirmPassword: [
                { required: true, message: '请确认密码', trigger: 'change' },
                {
                    validator: (rule, value, callback) => {
                        if (value !== registerForm.value.password) {
                            callback(new Error('两次输入的密码不一致'));
                        }
                        else {
                            callback();
                        }
                    },
                    trigger: 'change'
                }
            ]
        };
        // 获取验证码
        const getCaptcha = async () => {
            try {
                randomNumber.value = Math.floor(Math.random() * 100000);
                const response = await captcha(randomNumber.value);
                generatedCaptcha.value = response.data.result;
            }
            catch (error) {
                console.error('获取验证码失败', error);
            }
        };
        const login = () => {
            if (loginFormRef.value) {
                loginFormRef.value.validate((valid) => {
                    if (valid) {
                        console.log('登录信息:', loginForm.value);
                        localStorage.removeItem('authToken');
                        const encryptedPassword = CryptoJS.MD5(loginForm.value.password).toString();
                        const loginData = {
                            loginName: loginForm.value.loginName,
                            password: encryptedPassword,
                            loginType: "FENBI_INTEGRAL_MALL",
                            captcha: loginForm.value.captcha,
                            captchaKey: randomNumber.value
                        };
                        logins(loginData).then((res) => {
                            if (res.data.code === 200) {
                                showLoginDialog.value = false;
                                ElMessage.success('登录成功');
                                localStorage.setItem('authToken', res.data.result.token);
                                router.push('/pointsMall');
                            }
                            else {
                                ElMessage.error(res.data.message || '登录失败');
                                if (loginFormRef.value) {
                                    loginFormRef.value.resetFields();
                                }
                                getCaptcha(); // 登录失败时刷新验证码
                            }
                        }).catch((res) => {
                            ElMessage.error(res.data.message);
                            if (loginFormRef.value) {
                                loginFormRef.value.resetFields();
                            }
                            getCaptcha(); // 登录失败时刷新验证码
                        });
                    }
                    else {
                        console.log('验证失败');
                    }
                });
            }
        };
        const register = () => {
            if (registerFormRef.value) {
                disabled.value = false;
                registerFormRef.value.validate((valid) => {
                    if (valid) {
                        // 使用MD5加密密码
                        const encryptedPassword = CryptoJS.MD5(registerForm.value.password).toString();
                        const registerData = {
                            loginName: registerForm.value.loginName,
                            userName: registerForm.value.userName,
                            password: encryptedPassword
                        };
                        console.log('注册信息:', registerData);
                        localStorage.removeItem('authToken');
                        // 调用注册 API
                        registers(registerData).then((res) => {
                            if (res.data.code === 200) {
                                ElMessage.success('注册成功');
                                showRegisterDialog.value = false;
                                disabled.value = true;
                                if (registerFormRef.value) {
                                    registerFormRef.value.resetFields();
                                }
                            }
                            else {
                                ElMessage.error(res.data.message || '注册失败');
                            }
                        }).catch((res) => {
                            ElMessage.error(res.data.message);
                            if (registerFormRef.value) {
                                registerFormRef.value.resetFields();
                            }
                        });
                    }
                    else {
                        console.log('验证失败');
                    }
                });
            }
        };
        const openDialog = (type) => {
            if (type === 'login') {
                if (registerFormRef.value) {
                    registerFormRef.value.resetFields(); // 清空注册表单
                }
                showLoginDialog.value = true;
                getCaptcha(); // 每次打开登录对话框时获取新的验证码
            }
            else if (type === 'register') {
                if (registerFormRef.value) {
                    registerFormRef.value.resetFields(); // 清空注册表单
                }
                showRegisterDialog.value = true;
            }
        };
        const refreshCaptcha = () => {
            getCaptcha();
        };
        return {
            showLoginDialog,
            showRegisterDialog,
            loginFormRef,
            registerFormRef,
            loginForm,
            registerForm,
            loginRules,
            registerRules,
            randomNumber,
            login,
            register,
            openDialog,
            generatedCaptcha,
            refreshCaptcha
        };
    }
});
