import { defineComponent, ref, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { userAddress, defaultAddress, delateAddress } from '@/api/mall';
import { ElMessage } from 'element-plus';
export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const selectedAddressId = ref(null);
        const addresses = ref([]);
        const showDeleteDialog = ref(false);
        const addressToDelete = ref(null);
        // 判断是否从订单结算页面跳转过来
        const isFromOrderPage = route.query.fromOrder === 'true';
        console.log(isFromOrderPage);
        // 获取地址列表
        const getuserAddress = async () => {
            try {
                const res = await userAddress({});
                if (res && res.data) {
                    addresses.value = res.data.result.records.map((address) => ({
                        ...address,
                    }));
                }
            }
            catch (error) {
                console.error('获取地址列表失败:', error);
            }
        };
        const setDefaultAddressHandler = async (id) => {
            try {
                const res = await defaultAddress({ addressId: id });
                if (res.data.code === 200) {
                    ElMessage.success('设置默认地址成功');
                    await getuserAddress();
                }
                else {
                    ElMessage.error(res.data.message);
                }
            }
            catch (error) {
                console.error('设置默认地址失败:', error);
            }
        };
        const goBack = () => {
            router.back();
        };
        const addNewAddress = () => {
            router.push('/addAddress');
        };
        const edit = (address) => {
            router.push({
                path: '/addAddress',
                query: {
                    address: JSON.stringify(address),
                },
            });
        };
        const confirmDeleteAddress = (id) => {
            addressToDelete.value = id;
            showDeleteDialog.value = true;
        };
        const deleteAddress = async () => {
            if (addressToDelete.value !== null) {
                const res = await delateAddress(addressToDelete.value);
                if (res.data.code === 200) {
                    ElMessage.success('删除地址成功');
                    await getuserAddress();
                }
                else {
                    ElMessage.error(res.data.message || '删除地址失败');
                }
                showDeleteDialog.value = false;
                addressToDelete.value = null;
            }
        };
        const selectAddress = (id) => {
            selectedAddressId.value = id;
        };
        const handleAddressClick = (address) => {
            if (isFromOrderPage) {
                console.log(address);
                router.replace({
                    path: '/orderSummary',
                    query: {
                        selectedAddress: JSON.stringify(address),
                        id: route.query.id
                    },
                });
                router.go(-1);
            }
            else {
                selectAddress(address.id);
            }
        };
        const maskPhone = (phone) => {
            return phone ? phone.slice(0, 3) + '****' + phone.slice(-4) : '';
        };
        onMounted(() => {
            getuserAddress();
        });
        return {
            addresses,
            selectedAddressId,
            goBack,
            addNewAddress,
            edit,
            confirmDeleteAddress,
            deleteAddress,
            setDefaultAddress: setDefaultAddressHandler,
            selectAddress,
            showDeleteDialog,
            addressToDelete,
            handleAddressClick,
            maskPhone
        };
    },
});
