import { defineComponent, ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { userExchange } from '@/api/mall';
import { imgUrl } from "@/config";
export default defineComponent({
    setup() {
        const router = useRouter();
        const orders = ref([]);
        const getuserExchange = async () => {
            try {
                const res = await userExchange({});
                if (res && res.data) {
                    orders.value = res.data.result.records.map((item) => ({
                        id: item.id,
                        productName: item.goodsName,
                        points: item.goodsIntegral,
                        date: item.createTime,
                        image: `${imgUrl}${item.image} `,
                        userName: item.userName,
                        userPhone: item.userPhone,
                        userAddress: item.userAddress,
                    }));
                }
            }
            catch (error) {
                console.error('获取订单列表失败:', error);
            }
        };
        const maskPhone = (phone) => {
            return phone ? phone.slice(0, 3) + '****' + phone.slice(-4) : '';
        };
        const goBack = () => {
            router.back();
        };
        onMounted(() => {
            getuserExchange();
        });
        return {
            orders,
            goBack,
            maskPhone
        };
    }
});
