import { createStore } from 'vuex';
export default createStore({
    state: {
        isLoggedIn: false,
        user: null,
        points: 100,
        records: [],
        users: {}
    },
    mutations: {
        SET_LOGIN_STATE(state, payload) {
            state.isLoggedIn = payload.isLoggedIn;
            state.user = payload.user;
        },
        SET_POINTS(state, points) {
            state.points = points;
        },
        ADD_RECORD(state, record) {
            state.records.push(record);
        },
        SET_USERS(state, users) {
            state.users = users;
        }
    },
    actions: {
        login({ commit }, user) {
            commit('SET_LOGIN_STATE', { isLoggedIn: true, user });
        },
        logout({ commit }) {
            commit('SET_LOGIN_STATE', { isLoggedIn: false, user: null });
        },
        updatePoints({ commit }, points) {
            commit('SET_POINTS', points);
        },
        addRecord({ commit }, record) {
            commit('ADD_RECORD', record);
        },
        setUsers({ commit }, users) {
            commit('SET_USERS', users);
        }
    }
});
