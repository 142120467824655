import axios from 'axios';
import { useRouter } from 'vue-router';
const router = useRouter();
import { API_BASE_URL } from '@/config';
const apiClient = axios.create({
    // baseURL: 'https://pm.foemy.com:8443/integralapp', // 替换为你的服务器地址到
    baseURL: API_BASE_URL,
    timeout: 10000,
});
// 请求拦截器
apiClient.interceptors.request.use((config) => {
    const noAuthUrls = ['/captcha', '/registers', '/logins'];
    if (!noAuthUrls.some(url => config.url?.includes(url))) {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `${token}`;
        }
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
// 响应拦截器
apiClient.interceptors.response.use((response) => {
    if (response.data.code === 401) {
        // 清理 token，并跳转到登录页面
        localStorage.removeItem('authToken');
        // 使用 `window.location.href` 强制重载页面，确保在任何情况下都能正确跳转
        window.location.href = 'https://fenbi.foemy.com/';
        // router.push('/login')
    }
    return response;
}, (error) => {
    return Promise.reject(error);
});
export default apiClient;
