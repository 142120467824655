import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import FooterNavigation from '@/components/FooterNavigation.vue';
export default defineComponent({
    components: {
        FooterNavigation
    },
    setup() {
        const router = useRouter();
        const navigateTo = (page) => {
            if (page === 'orders') {
                router.push('/orderList');
            }
            else if (page === 'addresses') {
                router.push('/addressList');
            }
            else if (page === 'points') {
                router.push('/MyPoints');
            }
        };
        const logout = () => {
            localStorage.removeItem('authToken'); // 清除 token
            router.push('/login'); // 跳转到登录页面
        };
        return {
            navigateTo,
            logout
        };
    }
});
