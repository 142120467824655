import { defineComponent, ref, watch, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import dayjs from 'dayjs';
import { userGetIntegral, userGetIncome, userGetFreeze, userGetExpenditure } from '@/api/mall';
export default defineComponent({
    setup() {
        const router = useRouter();
        const activeTab = ref('expenditure');
        const selectedDateExpenditure = ref(null);
        const selectedDateIncome = ref(null);
        const selectedDateFrozen = ref(null);
        const expenditureRecords = ref([]);
        const incomeRecords = ref([]);
        const frozenRecords = ref([]);
        const totalPoints = ref(0);
        const availablePoints = ref(0);
        const frozenPoints = ref(0);
        const filteredExpenditureRecords = ref([]);
        const filteredIncomeRecords = ref([]);
        const filteredFrozenRecords = ref([]);
        const loading = ref(false);
        // 获取用户积分信息
        const getuserGetIntegral = async () => {
            try {
                const res = await userGetIntegral();
                if (res && res.data) {
                    totalPoints.value = res.data.result.allIntegral;
                    availablePoints.value = res.data.result.usableIntegral;
                    frozenPoints.value = res.data.result.freezeIntegral;
                }
            }
            catch (error) {
                console.error('获取积分信息失败:', error);
            }
        };
        // 获取支出记录
        const fetchExpenditureRecords = async () => {
            try {
                loading.value = true;
                const params = selectedDateExpenditure.value ? { month: dayjs(selectedDateExpenditure.value).format('YYYY-MM') } : {};
                const res = await userGetExpenditure(params);
                if (res && res.data) {
                    expenditureRecords.value = res.data.result.records;
                    filteredExpenditureRecords.value = expenditureRecords.value;
                }
            }
            catch (error) {
                console.error('获取支出记录失败:', error);
            }
            finally {
                loading.value = false;
            }
        };
        // 获取收入记录
        const fetchIncomeRecords = async () => {
            try {
                loading.value = true;
                const params = selectedDateIncome.value ? { month: dayjs(selectedDateIncome.value).format('YYYY-MM') } : {};
                const res = await userGetIncome(params);
                if (res && res.data) {
                    incomeRecords.value = res.data.result.records;
                    filteredIncomeRecords.value = incomeRecords.value;
                }
            }
            catch (error) {
                console.error('获取收入记录失败:', error);
            }
            finally {
                loading.value = false;
            }
        };
        // 获取冻结记录
        const fetchFrozenRecords = async () => {
            try {
                loading.value = true;
                const params = selectedDateFrozen.value ? { month: dayjs(selectedDateFrozen.value).format('YYYY-MM') } : {};
                const res = await userGetFreeze(params);
                if (res && res.data) {
                    frozenRecords.value = res.data.result.records;
                    filteredFrozenRecords.value = frozenRecords.value;
                }
            }
            catch (error) {
                console.error('获取冻结记录失败:', error);
            }
            finally {
                loading.value = false;
            }
        };
        // 监听 activeTab 的变化，根据选项卡的名称获取相应的数据
        watch(activeTab, async (newTab) => {
            if (newTab === 'expenditure') {
                await fetchExpenditureRecords();
            }
            else if (newTab === 'income') {
                await fetchIncomeRecords();
            }
            else if (newTab === 'frozen') {
                await fetchFrozenRecords();
            }
        });
        const filterExpenditureRecordsByDate = () => {
            if (selectedDateExpenditure.value) {
                const selectedMonth = dayjs(selectedDateExpenditure.value).format('YYYY-MM');
                filteredExpenditureRecords.value = expenditureRecords.value.filter(record => dayjs(record.createTime).format('YYYY-MM') === selectedMonth);
            }
            else {
                filteredExpenditureRecords.value = expenditureRecords.value;
            }
        };
        const filterIncomeRecordsByDate = () => {
            if (selectedDateIncome.value) {
                const selectedMonth = dayjs(selectedDateIncome.value).format('YYYY-MM');
                filteredIncomeRecords.value = incomeRecords.value.filter(record => dayjs(record.createTime).format('YYYY-MM') === selectedMonth);
            }
            else {
                filteredIncomeRecords.value = incomeRecords.value;
            }
        };
        const filterFrozenRecordsByDate = () => {
            if (selectedDateFrozen.value) {
                const selectedMonth = dayjs(selectedDateFrozen.value).format('YYYY-MM');
                filteredFrozenRecords.value = frozenRecords.value.filter(record => dayjs(record.useTime).format('YYYY-MM') === selectedMonth);
            }
            else {
                filteredFrozenRecords.value = frozenRecords.value;
            }
        };
        const goBack = () => {
            router.back();
        };
        onMounted(() => {
            getuserGetIntegral();
            fetchExpenditureRecords(); // 默认加载支出记录
        });
        return {
            activeTab,
            selectedDateExpenditure,
            selectedDateIncome,
            selectedDateFrozen,
            filteredExpenditureRecords,
            filteredIncomeRecords,
            filteredFrozenRecords,
            totalPoints,
            availablePoints,
            frozenPoints,
            loading,
            goBack,
            filterExpenditureRecordsByDate,
            filterIncomeRecordsByDate,
            filterFrozenRecordsByDate,
        };
    },
});
