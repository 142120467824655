import apiClient from './http';
/**
 * 注册用户
 * @param data
 */
export const registers = (data) => {
    return apiClient.post('/fb/integral/user/register', data);
};
/**
 * 用户登录
 * @param data
 */
export const logins = (data) => {
    return apiClient.post('/sys/thirdLogin', data);
};
/**
 * 获取验证码
 * @param randomNumber
 */
export const captcha = (randomNumber) => {
    return apiClient.get(`/sys/captcha/randomImage/${randomNumber}`);
};
