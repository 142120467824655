import apiClient from './http';
/**
 * 获取商品列表
 * @param params
 */
export const mallGoods = (params) => {
    return apiClient.get('/fb/integral/mall/goods?pageSize=1000000');
};
/**
 * 兑换商品
 * @param data
 */
export const mallExchange = (data) => {
    return apiClient.post('/fb/integral/mall/exchange', data);
};
/**
 * 获取订单列表
 * @param params
 */
export const userExchange = (params) => {
    return apiClient.get('/fb/integral/user/exchange?pageSize=1000000');
};
/**
 * 获取总、可用、冻结积分
 * @param params
 */
export const userGetIntegral = () => {
    return apiClient.get('/fb/integral/user/detail/getIntegral');
};
/**
 * 获取收入积分列表
 * @param params
 */
export const userGetIncome = (params) => {
    return apiClient.get('/fb/integral/user/detail/getIncome?pageSize=1000000');
};
/**
 * 获取冻结积分列表
 * @param params
 */
export const userGetFreeze = (params) => {
    return apiClient.get('/fb/integral/user/detail/getFreeze?pageSize=1000000');
};
/**
 * 获取支出积分列表
 * @param params
 */
export const userGetExpenditure = (params) => {
    return apiClient.get('/fb/integral/user/detail/getExpense?pageSize=1000000');
};
/**
 * 获取地址列表
 * @param params
 */
export const userAddress = (params) => {
    return apiClient.get('/fb/integral/user/address?pageSize=1000000');
};
/**
 * 删除地址
 * @param addressId
 */
export const delateAddress = (addressId) => {
    return apiClient.delete(`/fb/integral/user/address/${addressId}`);
};
/**
 * 删除地址
 * @param id
 */
export const querygoodsId = (id) => {
    return apiClient.get(`/fb/integral/mall/goods/${id}`);
};
/**
 * 新增地址
 * @param data
 */
export const addAddress = (data) => {
    return apiClient.post('/fb/integral/user/address', data);
};
/**
 * 修改地址
 * @param data
 */
export const EditAddress = (data) => {
    return apiClient.put('/fb/integral/user/address', data);
};
/**
 * 修改默认地址
 * @param data
 */
export const defaultAddress = (data) => {
    return apiClient.put('/fb/integral/user/address/default', data);
};
