import { defineComponent, ref, computed, onMounted } from 'vue';
import FooterNavigation from '@/components/FooterNavigation.vue';
import { useRouter } from 'vue-router';
import { mallGoods } from '@/api/mall';
import noDataImage from '@/image/nodata.jpg';
import { imgUrl } from "@/config";
export default defineComponent({
    components: {
        FooterNavigation,
    },
    setup() {
        const router = useRouter();
        const searchQuery = ref('');
        const products = ref([]);
        const loading = ref(true); // 加载状态
        const filteredProducts = computed(() => {
            return products.value.filter(product => product.name.toLowerCase().includes(searchQuery.value.toLowerCase()));
        });
        // 获取商品列表
        const getmallGoods = async () => {
            try {
                const res = await mallGoods({});
                if (res && res.data) {
                    products.value = res.data.result.records.map((item) => ({
                        id: item.id,
                        name: item.name,
                        points: item.integral,
                        remainingTimes: item.remindCount,
                        image: `${imgUrl}${item.image}`,
                    }));
                }
            }
            catch (error) {
                console.error('获取商品列表失败:', error);
            }
            finally {
                loading.value = false; // 数据加载完成
            }
        };
        const redeem = (product) => {
            if (product.remainingTimes > 0) {
                product.remainingTimes--;
                console.log(`兑换成功: ${product.name}`);
                router.push({
                    path: '/orderSummary',
                    query: {
                        id: product.id,
                        name: product.name,
                        points: product.points,
                        remainingTimes: product.remainingTimes,
                        image: product.image,
                    },
                });
            }
            else {
                console.log(`兑换失败: ${product.name} 次数已用完`);
            }
        };
        onMounted(() => {
            getmallGoods();
        });
        return {
            searchQuery,
            filteredProducts,
            redeem,
            noDataImage,
            loading,
        };
    },
});
