import { defineComponent, ref, computed, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { mallExchange, userAddress, querygoodsId } from '@/api/mall';
import { ElMessage } from 'element-plus';
import { imgUrl } from '@/config';
export default defineComponent({
    setup() {
        const router = useRouter();
        const route = useRoute();
        const addresses = ref([]);
        // 商品信息
        const productId = ref(route.query.id);
        const productName = ref();
        const productImage = ref();
        const productPoints = ref();
        const productRemainingTimes = ref();
        // 选中的地址
        const defaultAddress = ref(null);
        //获取商品详情
        const getquerygoodsId = async () => {
            const res = await querygoodsId(productId.value);
            productName.value = res.data.result.name;
            productImage.value = `${imgUrl}${res.data.result.image}`;
            productPoints.value = res.data.result.integral;
            productRemainingTimes.value = res.data.result.remindCount || 0;
        };
        // 获取地址列表
        const getuserAddress = async () => {
            try {
                const res = await userAddress({});
                if (res && res.data) {
                    addresses.value = res.data.result.records || [];
                    // 如果传递了地址，则使用传递的地址，否则使用默认地址或第一个地址
                    if (!defaultAddress.value) {
                        defaultAddress.value =
                            addresses.value.find((address) => address.defaultFlag === 1) || addresses.value[0];
                    }
                }
            }
            catch (error) {
                console.error('获取地址列表失败:', error);
            }
        };
        // 屏蔽电话号码中间四位
        const maskedPhone = computed(() => {
            const phone = defaultAddress.value?.userPhone || '';
            return phone ? phone.slice(0, 3) + '****' + phone.slice(-4) : '';
        });
        // 跳转到新增或修改地址页面
        const navigateToAddAddress = () => {
            router.push({
                path: addresses.value.length ? '/addressList' : '/addAddress',
                query: {
                    fromOrder: 'true',
                    id: productId.value
                }
            });
        };
        // 提交订单
        const submitOrder = async () => {
            if (!productId.value) {
                console.error('商品 ID 不存在');
                return;
            }
            const goods = {
                goodsId: productId.value,
                userAddress: defaultAddress.value?.userAddress || '',
                userPhone: defaultAddress.value?.userPhone || '',
                userName: defaultAddress.value?.userName || '',
            };
            try {
                const response = await mallExchange(goods);
                if (response.data.code === 200) {
                    router.push('/orderSuccess');
                }
                else {
                    ElMessage.error(response.data.message);
                }
            }
            catch (error) {
                console.error('兑换失败:', error);
            }
        };
        // 获取地址列表并处理传递过来的地址
        onMounted(() => {
            getuserAddress();
            getquerygoodsId();
            const address = route.query.selectedAddress;
            if (address) {
                try {
                    const parsedAddress = JSON.parse(address);
                    defaultAddress.value = parsedAddress;
                }
                catch (error) {
                    console.error('解析地址参数失败:', error);
                }
            }
        });
        return {
            goBack: () => router.back(),
            navigateToAddAddress,
            addresses,
            defaultAddress,
            maskedPhone,
            productId,
            submitOrder,
            productName,
            productPoints,
            productRemainingTimes,
            productImage,
        };
    },
});
